import React from 'react'
import {Link} from 'gatsby'
import { navigate } from 'gatsby-link'
import {useForm} from 'react-hook-form';
import Layout from '../../components/Layout'
import findUsFB from '../../img/social/FindUs-FB-RGB.svg'
import useSiteMetadata from "../../components/SiteMetadata";
import {Helmet} from "react-helmet";

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const Index = () => {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm();
    const { title: siteTitle } = useSiteMetadata();

    const submit = async (values, e) => {
        const form = e.target;
        return fetch('/api/contact', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...values,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    console.error(`${response.status} ${response.statusText} ${response.body}`);
                    alert("エラーにより送信できませんでした。内容をご確認の上、時間をおいてから再度お試しください。");
                    return false;
                } else {
                    navigate(form.getAttribute('action'))
                    return true;
                }
            })
            .catch((error) => {
                alert(error);
                return false;
            });
    };

    return (
        <Layout page="contact">
            <Helmet>
                <title>{siteTitle} - Contact</title>
            </Helmet>
            <div className="osi-contents">
                <div className="content">
                    <h1 className="title is-3">コンタクト</h1>
                    <hr />
                    <h2 className="title is-4">ご注意</h2>
                    <ul>
                        <li>勧誘・セールス・サービス・人材派遣等のご提案は、受け付けておりません。</li>
                        <li>現在、求人は行っておりません。</li>
                    </ul>
                    <p>※上記のお問い合わせには個別に回答しておりませんので悪しからずご了承ください。</p>
                </div>
                <div className="contact mb-5">
                    <Link to="https://www.facebook.com/OPENSPHERE.co.jp/" title="FindUs FB">
                        <img src={findUsFB} alt="Facebook" style={{ width: '200px' }} />
                    </Link>
                </div>
                <div className="content">
                    <h2 className="title is-4">お問い合わせフォーム</h2>
                    <form
                        name="contact"
                        method="post"
                        action={'/contact/thanks'}
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit(submit)}
                        data-static-form-name="contact"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                            <label>
                                Don't fill this out:{' '}
                                <input { ...register("bot-field")} />
                            </label>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'company'}>
                                貴社名 <span className="has-text-danger is-size-7">※必須</span>
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type={'text'}
                                    id={'company'}
                                    required={true}
                                    disabled={isSubmitting}
                                    { ...register("company", { required: true }) }
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'name'}>
                                お名前 <span className="has-text-danger is-size-7">※必須</span>
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type={'text'}
                                    id={'name'}
                                    required={true}
                                    disabled={isSubmitting}
                                    { ...register("name", { required: true }) }
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'email'}>
                                E-Mail <span className="has-text-danger is-size-7">※必須</span>
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type={'email'}
                                    id={'email'}
                                    required={true}
                                    disabled={isSubmitting}
                                    { ...register("email", { required: true }) }
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'tel'}>
                                お電話番号
                            </label>
                            <div className="control">
                                <input
                                    className="input"
                                    type={'tel'}
                                    id={'tel'}
                                    required={false}
                                    disabled={isSubmitting}
                                    { ...register("tel") }
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'message'}>
                                お問い合わせ内容 <span className="has-text-danger is-size-7">※必須</span>
                            </label>
                            <div className="control">
                                <textarea
                                    className="textarea"
                                    id={'message'}
                                    required={true}
                                    disabled={isSubmitting}
                                    { ...register("message", { required: true }) }
                                />
                            </div>
                        </div>
                        <div className="field">
                            <button
                                className={`button is-link ${isSubmitting ? "is-loading" : ""}`}
                                type="submit"
                                disabled={isSubmitting || !isValid}
                            >送信</button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default Index
